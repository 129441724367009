import React from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { RxDashboard, RxPerson, RxGear, RxHome, RxCrosshair1, RxCalendar } from "react-icons/rx";
import { BiCategory, BiDollarCircle } from "react-icons/bi";
import { BsBagCheck, BsChat, BsFilePostFill, BsSpeedometer2 } from "react-icons/bs";
import { MdCalendarToday, MdLocationCity, MdOutlinedFlag, MdOutlineLocationOn, MdOutlineManageAccounts, MdPages, MdSportsHandball, MdSportsTennis,MdAttachMoney, MdProductionQuantityLimits, MdOutlineRateReview, MdReportGmailerrorred } from "react-icons/md";
import Logo from "../assets/images/logo.png"
import { NavLink} from "react-router-dom";


const Navbar = () => {
  const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();
  return (
    <Sidebar
      id="sidebar"
      className="md:sticky fixed left-0 md:translate-x-0 -translate-x-full h-screen md:top-0 top-[70px] font-roboto z-50"
      backgroundColor="#39173c"
    >
      <div className="h-[70px] flex items-center justify-center">
        {/* <img className=" h-full mr-2 py-3" alt="..." src={Logo}/> */}
        <span className="text-[#fff] font-bold text-xl pl-[12px] pr-[80px]">{
          <img src={Logo}/>
        }</span>
      </div>
      <Menu >
        <NavLink className="link-main " to="/">
          <MenuItem icon={<BsSpeedometer2 />}> 
              Dashboard
          </MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/usermanagement">
          <MenuItem icon={<RxPerson />}> User Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/groupmanagement">
          <MenuItem icon={<RxPerson />} >Group Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/categorymanagement">
          <MenuItem icon={<RxPerson />}> Category Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/productmanagement">
          <MenuItem icon={<RxPerson />}> Product Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/paymentsmanagement">
          <MenuItem icon={<BiDollarCircle />}> Purchase Report</MenuItem>  
        </NavLink>
        {/* <NavLink className="link-main" to="/chatmanagement">
          <MenuItem icon={<BsChat />}> Chat Management</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/statusmanagement">
          <MenuItem icon={<BsFilePostFill />}> Status Management</MenuItem>  
        </NavLink> */}
       
       
        <NavLink className="link-main" to="/contactmanagement">
          <MenuItem icon={<BiDollarCircle />}>Enquiry List </MenuItem>  
        </NavLink>
        {/* <NavLink className="link-main" to="/reviewsmanagement">
          <MenuItem icon={<MdOutlineRateReview />}> Review Management</MenuItem>  
        </NavLink> */}
        {/* <NavLink className="link-main" to="/couponmanagement">
          <MenuItem icon={<MdReportGmailerrorred />}> Coupon Management</MenuItem>  
        </NavLink> */}
          <NavLink className="link-main" to="/reporteduser">
          <MenuItem icon={<MdReportGmailerrorred />}>Reported Users List</MenuItem>  
        </NavLink>
        <NavLink className="link-main" to="/cmsmanagement">
          <MenuItem icon={<MdPages />}>CMS Management</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/channelmanagement">
          <MenuItem icon={<MdPages />}>Channel Management</MenuItem>
        </NavLink>
        <NavLink className="link-main" to="/setting">
          <MenuItem icon={<RxGear />}>Settings</MenuItem>
        </NavLink>
      </Menu>
    </Sidebar>
  );
};

export default Navbar;
