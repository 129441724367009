import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { RxDashboard, RxEyeOpen, RxPencil1, RxPerson, RxPlus } from 'react-icons/rx';
import { useUserStore,UserStore,UserStoreContext } from "./store/UserStore";
import Filter from "./UserFilter/userfilter";
import Pagination from "react-js-pagination";
import { useEffect } from "react";
import Moment from "react-moment";


const UserManagement = () => {
   
    const store = new UserStore({
      isloading:true,
      editloading:false,
      pagginationcount:1
    });
    return(
      <UserStoreContext.Provider value={store}>
        <Screen/>
      </UserStoreContext.Provider>
    )
  }
  
  export default UserManagement;

  const Screen = observer(()=>{
    const {
      users,
      loaduser,
      nolist,
      filterVisibility,
      activePage,
      perPage, 
      Count,
      indexPage,
      filtertrue,
      getFilterUsers,
      getUsers,
      setIndexPage,
      setActivePage,
      statusChange,
      getstatusid,
      nofilterwithpagination,
      NofilterwithPagination
    } = useUserStore();

    const handlePageChange = (pageNumber) => {
      NofilterwithPagination(true)
      const element = document.getElementById("tablescroll");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        if(filtertrue){
          
          getFilterUsers(pageNumber)
          setIndexPage(pageNumber);
          setActivePage(pageNumber);
        }else{
          if (filterVisibility === true) {
            getUsers(pageNumber);
            setActivePage(pageNumber);
            setIndexPage(pageNumber);
          } else {
            setActivePage(pageNumber);
            setIndexPage(pageNumber);
          }
        }
      };
//  useEffect(() => {
//   loadTableData(1);
// }, []);

    
    return(
      <>
      <div className='flex justify-between'>
      <div className=" mb-4">
          <p className=" text-2xl font-semibold text-moredarkgreen">User Management</p>
          {/* <p className=" opacity-80 text-sm">Home / User</p> */}
        </div>
        {/* <div>
          <Link to="./adduser" className="btn btn-sm bg-[#000] text-green text-sm hover:bg-[#000]"><MdAdd/>Add User</Link>
        </div> */}
       </div>
       <Filter/>
       <div className="card bg-base-100 shadow-md p-6 mt-8">
        <div className="overflow-x-auto w-full">
        <table className="table table-zebra w-full">
        
            <thead>           
            <tr>
                <th>S.no</th>
                <th>Name</th>
                <th>Registration Date</th>
                {/* <th>Gender</th> */}
                {/* <th>Country Code</th> */}
                <th>Mobile</th>
                {/* <th>State</th> */}
                {/* <th>City</th> */}
                {/* <th>Country</th> */}
                <th>Last Logged In</th>
                <th>LAST LOGIN DEVICE</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody  id="tablescroll" className="text-sm relative">
            {loaduser && 
              <>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              <tr>
                <td colSpan={9} className="animate-pulse py-6"></td>
              </tr>
              </>
              }
            
            {users && users?.map((data,index)=> (
              <tr key={index}>
              <td>  {perPage * (indexPage - 1) + (index + 1)}</td>

              <td>
              <div className="flex items-center space-x-3">
                  <div>
                    {data.is_deleted == 1 ? 
                    
                    <>
                    <div className="font-bold capitalize">{(data?.first_name??"-")}</div>

                       <div className="text-[red] capitalize"><Moment format="MM/DD/YYYY">{data.deleted_date ?? "N/A"}</Moment></div>
                    </>
                    
                    
                    : <>
                    <div className="font-bold capitalize">{(data?.first_name??"-")}</div>
                  
                    <div className="capitalize text-xs">{data?.email}</div>                    
                    </>  }
                   
                    </div>
                    </div>
                </td>
                {/* <td className=" capitalize">{data?.gender??"-"}</td> */}
                {/* <td>{data?.country_code??"-"}</td> */}
                <td className="capitalize"><Moment format="MM/DD/YYYY">{data?.created_at}</Moment></td>
                <td>{data?.phone_number??"-"}</td>
                {/* <td>{data?.state?.name??"-"}</td> */}
                {/* <td>{data?.city?.name??"-"}</td> */}
                {/* <td className=" capitalize">{data?.country?.name??"-"}</td> */}
                <td className="capitalize"> {data?.last_seen ?  <Moment format="MM/DD/YYYY">{data?.last_seen}</Moment> : "N/A" } </td>
                <td className="capitalize">{data?.device_type ?? "N/A"}</td>
                <th>
                {data.is_deleted == 1 ?
                    <label
                    title="Change Status"
                    className={`btn capitalize ${ data.is_deleted == 1 && "bg-[red]"}  text-[#000] border-hidden  btn-xs`}
                    onClick={(e) => {
                      getstatusid(data?.id);
                      // getNewstatus(data?.isActive);
                    }}
                  >
                    {data.is_deleted == 1 && "Deleted" 
                    
                    }
                  </label>
                  :
                  <label
                  title="Change Status"
                  htmlFor="my-modal-5"
                  className={`btn capitalize ${data.status ? "bg-green hover:bg-green" : "bg-warning hover:bg-warning"}  text-[#000] border-hidden  btn-xs`}
                  onClick={(e) => {
                    getstatusid(data?.id);
                    // getNewstatus(data?.isActive);
                  }}
                >
                  {   data.status ? "Active" : "Inactive"  }
                </label>
                  }
                  </th>
                <td className=" text-base">
                    <div className="flex items-center space-x-4">
                      {/* <label
                        htmlFor="deletecity"
                        className=" text-[#d02c2c] cursor-pointer"
                        title="Delete"
                        // onClick={(e) => {getid(data?._id)}}
                      >
                        <MdOutlineDelete />
                      </label> */}
                       { data.is_deleted == 0 &&
                         <button title="Edit">
                         <Link to={`/usermanagement/${data?.id}`}>
                             <RxPencil1 />
                         </Link>
                       </button>
                      }
                    </div>
                  </td>
              </tr>
            ))}

            {nolist && 
              <tr>
                <td colSpan={9} className="my-10 text-center">
                  <RxPerson className=" text-[#dedede] text-8xl mx-auto" />
                  <p className=" font-semibold text-lg">No User Found</p>
                </td>
              </tr>
              }        
            
            </tbody>
        </table>
        
        <Pagination
          activePage={activePage}
          containerClassName={'pagination'}
          itemsCountPerPage={perPage}
          totalItemsCount={Number(Count)}
          pageRangeDisplayed={10}
          onChange={handlePageChange}
        />

        {/* status change model start */}
        <input type="checkbox" id="my-modal-5" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box relative">
              <label
                htmlFor="my-modal-5"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold text-center">
                Are you sure you want to change Status ?
              </h3>
              <div className="modal-action justify-center">
                <label
                  htmlFor="my-modal-5"
                  className="btn btn-md bg-darkgreen text-[#fff] text-sm hover:text-darkgreen hover:bg-lightgreen  ml-4"
                  onClick={() => statusChange()}
                >
                  Yes
                </label>
                <label htmlFor="my-modal-5" className="btn btn-md bg-lightgreen text-darkgreen text-sm hover:bg-darkgreen hover:text-[#fff]  ">
                  No
                </label>
              </div>
            </div>
          </div>
          {/* status change model end */}
        </div>
    </div>
       </>
    )
  })