import React from 'react'
import { MdBusiness, MdDelete, MdMoney, MdOutlinedFlag, MdProductionQuantityLimits, MdSell, MdSportsHandball, MdSportsTennis } from 'react-icons/md'
import { RxBackpack, RxCalendar, RxDashboard, RxPerson } from "react-icons/rx"
import { observer } from "mobx-react";
import { HomeStore , HomeStoreContext , useHomeStore } from '../Store/HomeStore';
import { BiDollarCircle, BiPurchaseTag } from 'react-icons/bi';
import { BsBagCheck } from 'react-icons/bs';
import { Link } from 'react-router-dom';
const CardDashbord = () => {
  const store = new HomeStore({
    isloading: true,
    editloading: false,
  });
  return (
    <HomeStoreContext.Provider value={store}>
      <Screen />
    </HomeStoreContext.Provider>
  );
};
export default CardDashbord;

const Screen  = observer(() => {

  const {dashboarddata} = useHomeStore()

  return (
    <div className="grid xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 gap-8">
      
      <Link to={`/usermanagement`}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_customers}</p>
              
              {/* <p className="text-[40px] font-semibold">100</p> */}

              <p className="text-base font-medium opacity-80 text-yellow">Total Customers</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <RxPerson/>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <Link to={`/usermanagement/deleteuser`}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_deleted_customers ?? "-"}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Deleted Users</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <MdDelete/>
              </div>
            </div>
          </div>
        </div>
        </Link>
        <Link to={"/paymentsmanagement"}>
        <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">${dashboarddata?.total_sell?? "-"}</p>

              <p className="text-base font-medium opacity-80 text-yellow">Total Sell</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <BiDollarCircle/>
              </div>
            </div>
          </div>
        </div>
        </Link>

        {/* <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_products}</p>
              <p className="text-[40px] font-semibold">100</p>

              <p className="text-base font-medium opacity-80 text-yellow">Total Products</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <MdProductionQuantityLimits/>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.total_orders}</p>
              <p className="text-[40px] font-semibold">100</p>

              <p className="text-base font-medium opacity-80 text-yellow">Total Orders</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
              <BsBagCheck />
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="card shadow-md p-6 group card-dash hover:text-[#fff] bg-darkgreen">
          <div className="flex ">
            <div className="w-9/12 text-[#fff]">
              <p className="text-[40px] font-semibold">{dashboarddata?.recent_purchase}</p>
              <p className="text-base font-medium opacity-80 text-yellow">Recent purchase</p>
            </div>
            <div className="w-3/12 flex">
              <div className=" text-yellow aspect-[1] w-full m-auto flex items-center justify-center text-[60px] rounded-lg">
                <BiPurchaseTag/>
              </div>
            </div>
          </div>
        </div> */}

      </div>
  )
});
